.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-start,
.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-end,
.ct-chart-bar .ct-label.ct-horizontal.ct-end,
.ct-label.ct-vertical.ct-start {
    color: black;
}

.ct-chart-pie .ct-label {
    font-size: 14px;
    font-weight: 600;
}

.ct-bar {
    stroke-width: 15px; 
}

.ct-series-a .ct-slice-pie {
    fill: #7cb5ec;
}

.ct-series-b .ct-slice-pie {
    fill: #434348;
}

.ct-series-a .ct-bar, .ct-series-a .ct-slice-pie {
    stroke: #7cb5ec !important;    
}

.ct-series-b .ct-bar, .ct-series-b .ct-slice-pie {
    stroke: #434348 !important;    
    stroke-width: 15px; 
}

.ct-chart-bar {
    margin-bottom: -50px !important;
}

.legendas {
    display: flex;
    justify-content: center;
    font-size: 12px;
    width: 100%;
}

.legendas section {
    margin: 0 10px
}

.legendas section div {
    display: inline-block;
    margin-right: 5px;
    border-radius: 25px;
}

.legendas_localidade {
    display: flex;
    flex-direction: row;
    font-size: 13px;
    justify-content: center;
    width: 100%;
}

.legendas_localidade section {
    margin: 0 10px
}


.legendas_localidade div {
    display: inline-block;
    margin-right: 5px;
    border-radius: 25px;
}

.dot {
    width: 10px;
    height: 10px;
    
}

.dot__masc {
    background-color: #7cb5ec;
}

.dot__fem {
    background-color: #434348;
}


.ct-label.ct-vertical.ct-start {
    
}